import logo from './assets/Motive logo.png';
import A from './assets/logo1.png';
import B from './assets/logo2.png';
import E from './assets/logo4.png';
import F from './assets/logo5.png';
import web from './assets/web.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import FadeIn from 'react-fade-in/lib/FadeIn';
import pic from './assets/65831874734bfd6feb32bc97.png';
import './App.css';
import axios from 'axios';
import Home from './components/Home/Home';
import React, {useState} from 'react';
import Modal from 'react-modal';


function App() {

  const subscribeToSendPulse = async (email, name) => {
    try {
      const apiKey = 'YOUR_SENDPULSE_API_KEY';
      const listId = 'YOUR_LIST_ID'; // Replace with your SendPulse list ID
  
      const response = await axios.post(
        'https://api.sendpulse.com/addressbooks/' + listId + '/emails',
        {
          emails: [{ email, variables: { fname } }],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Api-Key': apiKey,
          },
        }
      );
  
      console.log('Subscription successful', response.data);
      // Handle success or update UI accordingly
    } catch (error) {
      console.error('Error subscribing to SendPulse', error);
      // Handle error or update UI accordingly
    }
  };


  const [email, setEmail] = useState('');
  const [fname, setName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add any form validation logic here

    // Call the function to subscribe to SendPulse
    subscribeToSendPulse(email, fname);
  }

  const [open, setOpen] = useState(false); 

  return ( 
    <>
    <div className="App" >
            
      <Home />
      <footer>
          <p>Copyrigth @2024 Motive Marketing</p>
      </footer>
    </div>
    </>
  );
}

export default App;
