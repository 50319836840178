import React from 'react';
import logo from '../../assets/Motive logo.png';
import './Thank.css'

function Thank() {
  return (
      <div className='sec'>
        <div className='container'>
            <div className='back'>
                <div className='upper'>
                    <div className='IMG'>
                        <img src={logo}/>                 
                    </div>
                    <div className='moto'>
                        <div className='headtext'>
                          <p><strong>CREATE MODERN DESIGNS THAT FOLLOW THE NEWEST TRENDS.</strong></p>
                        </div>                
                    </div>
                    <div className='center'>
                      <div className='div-text'>
                          <p>THANKS FOR BOOKING CALL WITH MOTIVE MARKETING. SEE YOU SOON</p>                  
                      </div>                 
                    </div>
                </div>
            </div>
            <footer>
             <p>Copyrigth @2024 Motive Marketing</p>
            </footer>
        </div> 
      </div>
  )
}

export default Thank
