import React, {useState, useEffect} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import './Popup.css'

function Form() {

    const [open, setOpen] = useState(false);

    useEffect(() => {
         setOpen(true);
    }, []);

    const closePopup = () =>{
        setOpen(false);
    }

  return (
    <div>
      <div className='MAIN'>
        <div className='container'>
            <div className={`popup ${open ? 'active' : ''}`} id='innerdiv'>                
                    <form className="regForm" onLoad= {() => setOpen(true)} >                                           
                            <h1 className="register">Ready To Thrive </h1> 
                            <div className='form-group' style={{marginLeft: '60px'}} >
                                <div className="mb-3 row">  
                                    <label htmlFor="formGroupExampleInput"  className="col-sm-3 col-form-label"  style={{color: '#1FA2FF', fontWeight: 'bold'}}>Name</label>
                                    <div className="col-sm-11">           
                                    <input type="text" className="form-control" placeholder="Name..."  name="fname"/>
                                    </div>
                                </div> 
                                <div className="mb-3 row">  
                                    <label htmlFor="formGroupExampleInput"  className="col-sm-3 col-form-label" style={{color: '#1FA2FF', fontWeight: 'bold'}}>Username</label>
                                    <div className="col-sm-11">           
                                    <input type="text" className="form-control" placeholder="Username..."  name="fname"/>
                                    </div>
                                </div> 
                                <div className="mb-3 row">  
                                    <label htmlFor="formGroupExampleInput"  className="col-sm-3 col-form-label" style={{color: '#1FA2FF', fontWeight: 'bold'}}>Email</label>
                                    <div className="col-sm-11">           
                                    <input type="text" className="form-control" placeholder="name@gmail.com..."  name="fname"/>
                                    </div>
                                </div> 
                                <div className="mb-3 row">  
                                    <label htmlFor="formGroupExampleInput"  className="col-sm-3 col-form-label" style={{color: '#1FA2FF', fontWeight: 'bold'}}>Phone Number</label>
                                    <div className="col-sm-11">           
                                    <input type="text" className="form-control" placeholder="..."  name="fname"/>
                                    </div>
                                </div>                            
                                                 
                            <div className="next" onClick={closePopup}>
                            <div>
                              <button type="button" className="prevBtn">
                                Submit 
                                <FontAwesomeIcon icon={faAngleDoubleRight} />
                                </button> 
                            </div>
                            </div> 
                        </div>                    
                    </form>               
            </div>
        </div>
      </div>
    </div>
  )
}

export default Form
