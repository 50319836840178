import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Thank from './components/Thank/Thank';
import Home from './components/Home/Home';
import Form from './components/Popup/Popup';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/form",
    element: <Form/>,
  },
  {
    path: "/index",
    element: <Home/>,
  },
  {
    path: "/thank-u",
    element: <Thank/>,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}/>
);

reportWebVitals();
