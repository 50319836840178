import React from 'react'
import logo from '../../assets/Motive logo.png';
import A from '../../assets/logo1.png';
import B from '../../assets/logo2.png';
import E from '../../assets/logo4.png';
import F from '../../assets/logo5.png';
import web from '../../assets/web.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import FadeIn from 'react-fade-in/lib/FadeIn';
import pic from '../../assets/65831874734bfd6feb32bc97.png';
import { InlineWidget } from 'react-calendly';
import '../../App.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';

function Home() {
  return (
    <div>
      <div className='main'>
        <section className='section1'>
         <div className='bg'> 
           <div className='innsec'>
              <div className='logo'>
                <img src={logo}/>                 
              </div>
                <div className='headsec'>
                  <div className='h1'>
                    <p><strong>CREATE MODERN DESIGNS THAT FOLLOW THE NEWEST TRENDS.</strong></p>
                  </div>                
                </div>
                <div className='middle'>
                  <div className='p'>
                    <p>BOOST YOUR ONLINE LOOK IN 2024  WITH THE LATEST WEBSITE STYLE.</p>                  
                  </div>                 
                </div>
                <div className='image'>
                  <img src={pic}/>
                </div>
                <div className='btn'>
                <div>
                  <AnchorLink href='#calender'>                 
                    <button className='button1'>                    
                      <div className='head'>YES! I WANT TO THRIVE IN 2024</div>
                      <div className='subhead'>HURRY UP! LIMITED SPOTS AVAILABLE</div>                                           
                    </button>                   
                  </AnchorLink>                 
                  </div>
                </div>
                
                <div className='line'>
                  <div><FontAwesomeIcon icon ={faLock} /> 100% SECURE. NO SPAM. MONEY BACK GARUNTEE</div>
                </div>
           </div>
         </div>
        </section>
        <section className='section2'>
          <div className='head2'>
            <h1>CLIENTS THAT TRUST MOTIVE MARKETING</h1>
          </div>           
          <div className='logos2'>
            <div className='logos1'>
              <img src={A}></img>
              <img src={B}></img>
              <img src={E}></img>
                        
            </div>
            <div className='innerlogo'>
                <img src={F}></img>
              </div>                      
          </div>
        </section>
        <section className='section3'>
          <div className='secmain'>
            <div className='Lsec'> 
              <img src={web}></img>
            </div>
            <div className='Rsec'>
                <div className='tag'>
                  <div className='p1'>
                    <p>GET A GREAT WEBSITE AS YOU STEP INTO 2024</p>                  
                  </div>                 
                </div>
                <div className='H1'>
                   <h1>
                   The calendar is turning to 2024, so freshen up your online strategy. Our skilled team is all about creating creative websites.
                   </h1>
                </div>
                <div className='btn1'>                  
                  <div>
                    <AnchorLink href='#calender'>
                      <button className='button' >                      
                        <div className='head'>YES! I WANT TO THRIVE IN 2024</div>
                        <div className='subhead'>HURRY UP! LIMITED SPOTS AVAILABLE</div>
                      </button>
                    </AnchorLink>
                  </div>
                  <div className='line1'>
                    <div><FontAwesomeIcon icon ={faLock} /> 100% SECURE. NO SPAM. MONEY BACK GARUNTEE</div>
                  </div>
                </div>
              </div>
          </div>
        </section>
        <section className='section5'>
          <div className='goal'>
            <div className='Gh1'>
               <div className='p3'>WHY US FOR YOUR 2024 WEB GOALS?</div>
             </div>
            <div className='Gmain'>
            <FadeIn>
              <div className='inner'>
                <div className='innerL'>
                  <div className='text'>CUSTOMIZED FOR THE NEW YEAR</div>
                </div>
                <div className='innerR'>
                  <div className='text'>FULLY RESPONSIVE DESIGN</div>
                </div>
              </div>
              <div className='inner'>
                <div className='innerL'>
                  <div className='text'>SEO OPTIMIZED FOR 2024</div>
                </div>
                <div className='innerR'>
                  <div className='text'>USER CENTRIC DESIGNS</div>
                </div>
              </div>
              <div className='inner'>
                <div className='innerL'>
                  <div className='text'>BESPOKE DESIGNS</div>
                </div>
                <div className='innerR'>
                  <div className='text'>RESPONSIVE AND MOBILE-FRIENDLY</div>
                </div>
              </div>
            </FadeIn> 
            </div>            
          </div>
        </section>
        <section className='section4'>
          <div className='last'>
            <div className='Lh1'>
               <div className='p2'>TURN YOUR THOUGHTS INTO SOMETHING YOU CAN TOUCH</div>
            </div>
            <div className='H3'>
                   <h1>
                   We're a skilled team dedicated to making websites that look fantastic and offer unbeatable user experiences. Whether you're a small business, a growing company, or an individual wanting to boost your personal brand, our web designs connect with your audience.
                   </h1>
                </div>
                <div className='btn2'>
                  <div>
                    <AnchorLink href='calender'>
                      <button className='button1'>
                        <div className='head'>YES! I WANT TO THRIVE IN 2024</div>
                        <div className='subhead'>HURRY UP! LIMITED SPOTS AVAILABLE</div>
                      </button>
                    </AnchorLink>
                  </div>
                </div>
                
                <div className='line2'>
                  <div><FontAwesomeIcon icon ={faLock} /> 100% SECURE. NO SPAM. MONEY BACK GARUNTEE</div>
                </div>
            </div>            
        </section>
        <section className='section6'>
          <div className='cal-sec' id='calender'>
           <InlineWidget url='https://calendly.com/motive-marketing/30-minutes-meeting-motive-marketing'  rootElement={document.getElementById('root')} />
          </div>
        </section>        
      </div>
    </div>
  )
}

export default Home
